<template>
  <div v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: componentTitle })}]" class="button-group-fixed-container">
    <div class="wrapper">
      <div v-if="renderData.showIcon" class="icon-box">
        <img v-lazy-load :data-src="remind.iconUrl" alt="" v-lazy-load>
      </div>
      <div v-if="renderData.showDescribe" class="text-description">
        {{ describe }}
      </div>
      <div v-if="renderData.showRenew" class="renewLink">
        <a :href="renew.linkUrl" :target="renew.target" class="one-line ellipsis" @click="cardTrackReport(renew.linkDescribe)">{{ renew.linkDescribe }}</a>
      </div>
      <div class="btn-group">
        <el-button v-if="renderData.showBtn1" type="primary" round :plain="renderData.btn1Plain">
          <a v-if="btn1.link" :target="btn1.target" :href="btn1.link" class="ellipsis" @click="cardTrackReport(btn1.text)">{{ btn1.text }}</a>
          <span v-else class="ellipsis">{{ btn1.text }}</span>
        </el-button>
        <el-button v-if="renderData.showBtn2" type="primary" round :plain="renderData.btn2Plain">
          <span v-if="!btn2.link" class="ellipsis">{{ btn2.text }}</span>
          <span v-if="!isLogin && type === 2" class="ellipsis" @click="jumpTo(btn2.link)">{{ btn2.text }}</span>
          <a v-else id="fixed-btn2" :href="btn2.link" :target="btn2.target" class="ellipsis" @click="cardTrackReport(btn2.text)">{{ btn2.text }}</a>
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import bus from 'common/bus'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import mixin from './mixin'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      current: 'web',
    }
  },
  methods: {
    jumpTo() {
      const currentFullPath = this.$route.fullPath
      const successUrl = encodeURI(currentFullPath)
      bus.$emit('login', { successUrl })
    },
  },
}
</script>
<style lang="scss" scoped>
.button-group-fixed-container {
  height: auto;
  padding: 16px 64px;
  background: #fff;
  box-shadow: 0 5px 8px 0 rgba(180, 180, 180, 0.2);
  font-size: 14px;
  font-weight: 400;

  .wrapper {
    width: 1240px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .one-line {
    white-space: nowrap;
  }

  .icon-box {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .text-description {
    color: var(--txt-color-lv2);
    line-height: 22px;
    max-width: 45%;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }

  .renewLink {
    max-width: 45%;
    height: 22px;
    color: var(--txt-color-lv2);
    line-height: 22px;
    overflow: hidden;
    margin-left: 12px;
    border-bottom: 1px solid var(--txt-color-link);

    a {
      color: var(--txt-color-link);
    }
  }

  .btn-group {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 36px;
    display: flex;
    align-items: center;

    .el-button {
      min-width: 137px;
      height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 70px;
      font-weight: 400;
      color: var(--txt-color-link);
      background: var(--txt-color-link3);
      border-color: var(--txt-color-link);

      &:hover {
        background: var(--txt-color-link4);
      }

      a,
      span {
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
        line-height: 36px;
      }

      & + .el-button {
        margin-left: 16px;
      }
    }
  }
}
</style>
