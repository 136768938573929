// import bus from 'common/bus'
import { oneApi } from 'config/api'

export default {
  name: 'ButtonGroupFixed',
  props: {
    // 组件名称
    componentName: {
      default: 'ButtonGroupFixed',
      type: String,
    },
    // 组件类型
    componentType: {
      default: 0,
      type: Number,
    },
    // 组件标题
    componentTitle: {
      default: '',
      type: String,
    },
    // 模块id
    moduleId: {
      default: '',
      type: String,
    },
    // 子类型1组件，自适应学习
    // 子类型2组件，展示型
    // 组件类型：2，子类型1组件；1，子类型2组件；
    type: {
      default: 2,
      type: Number,
    },
    // 1，未购买；2，已购买，在服务期内；3，已购买，服务已过期
    // status: {
    //   type: Number,
    //   default: 1,
    // },
    // 提醒文案
    remind: {
      type: Object,
      default: () => ({}),
    },
    // 服务日期
    expirationTime: {
      type: Object,
      default: () => ({}),
    },
    // 续费
    renew: {
      type: Object,
      default: () => ({}),
    },
    // 按钮1
    btn1: {
      type: Object,
      default: () => ({}),
    },
    // 按钮2
    btn2: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // status, 1，未购买；2，已购买，在服务期内；3，已购买，服务已过期
      resData: {},
      renderData: {},
      describe: '',
      clickCount: 0,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
  },
  watch: {
    isLogin() {
      if (this.isLogin) {
        this.changeData()
      }
    },
  },
  async mounted() {
    if (this.isLogin) await this.getStatus()
    if (!this.isLogin || Object.keys(this.resData).length > 0) this.handleData()
    this.componentShowReport()
    this.cardShowReport()
  },
  methods: {
    // 获取用户登录态
    async getStatus() {
      const res = await this.$axios.post(oneApi.queryStatus, {}, { rewritePostBody: true })
      if (res.code === 0) {
        this.resData = { ...res.data }
      }
    },
    async changeData() {
      await this.getStatus()
      this.handleData()
    },
    handleData() {
      const { status } = this.resData
      // 子类型2
      if (this.type === 1) {
        this.renderData = {
          showIcon: this.remind.iconUrl,
          showDescribe: this.remind.describe,
          showRenew: false,
          showBtn1: !!(this.btn1.text && this.btn1.link),
          btn1Plain: !!(this.btn1.text && this.btn2.text),
          showBtn2: !!(this.btn2.text && this.btn2.link),
          btn2Plain: false,
        }
        if (this.current === 'touch') {
          this.style = '2'
        }
        this.describe = this.remind && this.remind.describe ? this.remind.describe : ''
        return
      }

      // 子类型1
      // 用户未登录
      if (!this.isLogin) {
        this.renderData = {
          showIcon: false,
          showDescribe: false,
          showRenew: false,
          showBtn1: !!(this.btn1.text && this.btn1.link),
          btn1Plain: !!(this.btn1.text && this.btn2.text),
          showBtn2: !!(this.btn2.text && this.btn2.link),
          btn2Plain: false,
        }
        this.describe = ''
        if (this.current === 'touch') {
          this.style = '1'
        }
        return
      }

      // 用户已经登录AND未购买服务
      if (status === 1) {
        this.renderData = {
          showIcon: false,
          showDescribe: false,
          showRenew: false,
          showBtn1: !!(this.btn1.text && this.btn1.link),
          btn1Plain: false,
          showBtn2: false,
          btn2Plain: false,
        }
        this.describe = ''
        if (this.current === 'touch') {
          this.style = '1'
        }
        return
      }

      // 用户已经登录AND用户在服务期内
      if (status === 2) {
        this.renderData = {
          showIcon: false,
          showDescribe: this.expirationTime.describe,
          showRenew: false,
          showBtn1: false,
          btn1Plain: false,
          showBtn2: !!(this.btn2.text && this.btn2.link),
          btn2Plain: false,
        }
        this.describe = `${this.expirationTime.describe}: ${this.format(new Date(this.resData.serviceEndTimeStr), this.expirationTime.format)}`
        if (this.current === 'touch') {
          this.style = '1'
        }
        return
      }

      // 用户已经登录AND用户服务已过期
      this.renderData = {
        showIcon: false,
        showDescribe: this.renew.describe,
        showRenew: !!(this.renew.linkDescribe && this.renew.linkUrl),
        showBtn1: false,
        btn1Plain: false,
        showBtn2: !!(this.btn2.text && this.btn2.link),
        btn2Plain: false,
      }
      this.describe = this.renew.describe
      if (this.current === 'touch') {
        this.style = '1'
      }
    },
    format(date, fmt1) {
      let fmt = fmt1
      const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
      };
      // (y+)匹配多个y，比如yyyy
      if (/(y+)/.test(fmt)) {
        // RegExp.$1是RegExp的一个属性,指的是与正则表达式匹配的第一个 子匹配(以括号为标志)字符串
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
        }
      }
      return fmt
    },
    componentShowReport() {
      this.Sensors.track('component_show', {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_id: this.moduleId,
      })
    },
    /**
     * 组件卡片通用数据上报
     * @param {Object} cardName 按钮名称
     */
    cardShowReport() {
      this.Sensors.track('component_card_show', {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_id: this.moduleId,
        card_name: this.describe,
        previous_source: this.$route.name,
      })
    },
    /**
     * 组件卡片通用数据上报
     * @param {Object} cardName 按钮名称
     */
    cardTrackReport(name) {
      this.Sensors.track('component_button_click', {
        cms_component_type: this.componentType,
        cms_component_name: this.componentName,
        cms_component_id: this.moduleId,
        click_type: 2, // 1: 卡片 2: 按钮
        card_name: this.describe,
        previous_source: this.$route.name,
        button_name: name,
      })
    },
  },
}
