<template>
  <div>
    <div class="home-wrapper">
      <Header v-if="pageType != 3" :sticky-status="false" />
      <div v-for="item in btnComponentList" :key="item.key" class="btn-fixed-wrapper">
        <ButtonGroupFixed
          :component-name="item.content.componentName"
          :component-type="item.content.componentType"
          :component-title="item.content.componentTitle"
          :module-id="item.id"
          v-bind="item.content.componentData"
        />
      </div>
    </div>
    <DynamicComponent v-if="!!componentList.length" class="container" :locale="locale" :component-list="componentList" style="padding: 40px 0;" />
    <Footer v-if="pageType != 3" />
    <ToolsGroup  v-if="pageType != 3" />
    <no-ssr>
      <CookiesStatement v-if="showCookiesStatement" />
    </no-ssr>
  </div>
</template>

<script>
import DynamicComponent from '@/components/global/DynamicComponents/web'
import Header from '@/components/global/FeatureComponents/Header/web'
import Footer from '@/components/global/FeatureComponents/Footer/web'
import ButtonGroupFixed from '~/components/global/DynamicComponents/ButtonGroupFixed/web'
import CookiesStatement from 'components/common/CookiesStatement'
import ToolsGroup from 'components/common/ToolsGroup/index.vue';
// import { createScript } from '../../libs/token'
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  layout: 'simpleLayout',
  middleware: ['queryCustomPage'],
  components: {
    DynamicComponent,
    Header,
    Footer,
    CookiesStatement,
    ToolsGroup,
    ButtonGroupFixed,
  },
  data() {
    const { locale } = this.$store.state
    return {
      locale,
      showCookiesStatement: locale !== 'hk',
    }
  },
  computed: {
    componentList() {
      return this.$store.state.customPage.pageModuleList.filter((item) => item.type !== 49)
    },
    pageType() {
      return this.$store.state.customPage.type
    },
    btnComponentList() {
      let componentList = this.$store.state.customPage.pageModuleList.filter((item) => item.type === 49)
      componentList = componentList.map((item) => {
        const data = {}
        try {
          const v = JSON.parse(item.content)
          data.content = v
        } catch (err) {
          console.log('err is ', err)
        }

        return { ...item, ...data }
      })
      return componentList
    },
  },
  mounted() {
    // const { verifyKey } = process.env;
    // gRecaptcha.createScript.call(this, verifyKey)
    this.getTrackData()
  },
  methods: {
    getTrackData() {
      const sourceType = this.$route.query.source_type || window.sessionStorage.getItem('source_type') || ''
      const sourceId = this.$route.query.source_id || window.sessionStorage.getItem('source_id') || ''
      this.Sensors.track('component_page_pv', {
        source_id: sourceId,
        source_type: sourceType,
      })
    },
  },
  head() {
    // 限制弹窗缩放
    if (this.$store.state.locale !== 'ae') return {}
    return {
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: '/edulogo.ico',
      }],
    }
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
<style>
.grecaptcha-badge { visibility: hidden; }
</style>
